import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cta from "../components/cta"
import { Jumbotron, Container, Card, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

const Hero = styled(Jumbotron)`
  text-align: center;
  margin-bottom: 0;
  border-radius: 0;
`

const Section = styled.div`
  padding-top: 4rem;
  padding-bottom: 5rem;
  .header {
    margin-bottom: 2rem;
    h3 {
      text-align: center;
    }
    p {
      max-width: 600px;
      margin: 0 auto;
      text-align: center;
    }
  }
  .card {
    height: 100%;
  }
`

const ProductsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      battery1: file(relativePath: { eq: "products/acdelco-m27.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, toFormat: PNG) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      battery2: file(relativePath: { eq: "products/acdelco-31s.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, toFormat: PNG) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      battery3: file(relativePath: { eq: "products/acdelco-sn200.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, toFormat: PNG) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      tester1: file(relativePath: { eq: "products/midtronics-cbt200.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, toFormat: PNG) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      tester2: file(relativePath: { eq: "products/midtronics-mdx-300.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, toFormat: PNG) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      tester3: file(relativePath: { eq: "products/midtronics-mdx-600.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, toFormat: PNG) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      charger1: file(relativePath: { eq: "products/kozar-charger.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, toFormat: PNG) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      charger2: file(relativePath: { eq: "products/acdelco-charger.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, toFormat: PNG) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      charger3: file(relativePath: { eq: "products/midtronics-mcc070.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, toFormat: PNG) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Layout removeContainer>
      <SEO title='Products' />
      <Hero className='bg-gradient'>
        <h1 className='text-white'>Our Products</h1>
      </Hero>

      <Section id='batteries'>
        <Container>
          <div className='header'>
            <h3>Batteries</h3>
            <p>
              We stock and supply full ranges of quality batteries for all
              requirements, large or small, basic or high-end. Our batteries are
              maintenance-free and fully-sealed to last longer, perform better
              at all temperatures, and eliminate gassing and acid spewing
              problems.
            </p>
          </div>
          <Row>
            <Col className='mb-2 mb-sm-4' md='6' lg='3'>
              <Card>
                <Img
                  css={css`
                    height: 300px;
                  `}
                  fluid={data.battery1.childImageSharp.fluid}
                />
                <Card.Body className='bg-dark text-white'>
                  <Card.Title>MARINE BATTERY</Card.Title>
                  <Card.Text>ACDELCO</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className='mb-2 mb-sm-4' md='6' lg='3'>
              <Card>
                <Img
                  css={css`
                    height: 300px;
                  `}
                  fluid={data.battery2.childImageSharp.fluid}
                />
                <Card.Body className='bg-dark text-white'>
                  <Card.Title>SOLAR & INDUSTRIAL</Card.Title>
                  <Card.Text>ACDELCO</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className='mb-2 mb-sm-4' md='6' lg='3'>
              <Card>
                <Img
                  css={css`
                    height: 300px;
                  `}
                  fluid={data.battery3.childImageSharp.fluid}
                />
                <Card.Body className='bg-dark text-white'>
                  <Card.Title>HEAVY DUTY</Card.Title>
                  <Card.Text>ACDELCO</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className='d-none d-md-block mb-sm-4' md='6' lg='3'>
              <Card className='px-3 h-100 bg-dark text-white d-flex align-items-center text-center'>
                <div className='my-auto'>
                  <p className='mb-3'>
                    For more battery models.. <br />
                  </p>
                  <Link to='/contact-us' className='btn btn-sm btn-primary'>
                    Contact us now!
                  </Link>
                </div>
              </Card>
            </Col>
          </Row>
          <div className='d-md-none mt-4 text-center'>
            <p className='mb-2 text-center'>For more battery models.. </p>
            <Link to='/contact-us' className='btn btn-primary col-8'>
              Contact us for more details
            </Link>
          </div>
        </Container>
      </Section>

      <Section fluid className='bg-light' id='testers'>
        <Container>
          <div className='header'>
            <h3>Testers</h3>
            <p>
              Fast, safe and accurate, our range of digital battery testers is
              the ultimate in diagnostic tools for your batteries. We are the
              authorised representative/dealer of US-based Midtronics, developer
              of the world's most advanced battery testing technology.
            </p>
          </div>
          <Row>
            <Col className='mb-2 mb-sm-4' md='6' lg='3'>
              <Card>
                <Img
                  css={css`
                    height: 300px;
                  `}
                  fluid={data.tester1.childImageSharp.fluid}
                />
                <Card.Body className='bg-dark text-white'>
                  <Card.Title>CBT 200</Card.Title>
                  <Card.Text>MIDTRONICS</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col className='mb-2 mb-sm-4' md='6' lg='3'>
              <Card>
                <Img
                  css={css`
                    height: 300px;
                  `}
                  fluid={data.tester2.childImageSharp.fluid}
                />
                <Card.Body className='bg-dark text-white'>
                  <Card.Title>MDX P300</Card.Title>
                  <Card.Text>MIDTRONICS</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col className='mb-2 mb-sm-4' md='6' lg='3'>
              <Card>
                <Img
                  css={css`
                    height: 300px;
                  `}
                  fluid={data.tester3.childImageSharp.fluid}
                />
                <Card.Body className='bg-dark text-white'>
                  <Card.Title>MDX 600</Card.Title>
                  <Card.Text>MIDTRONICS</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className='d-none d-md-block mb-sm-4' md='6' lg='3'>
              <Card className='px-3 h-100 bg-dark text-white d-flex align-items-center text-center'>
                <div className='my-auto'>
                  <p className='mb-3'>
                    For more tester models.. <br />
                  </p>
                  <Link to='/contact-us' className='btn btn-sm btn-primary'>
                    Contact us now!
                  </Link>
                </div>
              </Card>
            </Col>
          </Row>
          <div className='d-md-none mt-4 text-center'>
            <p className='mb-2 text-center'>For more tester models.. </p>
            <Link to='/contact-us' className='btn btn-primary col-8'>
              Contact us now!
            </Link>
          </div>
        </Container>
      </Section>

      <Section id='chargers'>
        <Container>
          <div className='header'>
            <h3>Chargers</h3>
            <p>
              To be the complete solution-provider of stand-by power. We
              distribute the latest intelligent battery chargers. Incorporating
              the testing and charging components, while providing accurate and
              full analysis of battery condition.
            </p>
          </div>
          <Row>
            <Col className='mb-2 mb-sm-4' md='6' lg='3'>
              <Card>
                <Img
                  css={css`
                    height: 300px;
                  `}
                  fluid={data.charger1.childImageSharp.fluid}
                />
                <Card.Body className='bg-dark text-white'>
                  <Card.Title>MOTORCYCLE CHARGER</Card.Title>
                  <Card.Text>KOZAR</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className='mb-2 mb-sm-4' md='6' lg='3'>
              <Card
                css={css`
                  height: 100%;
                `}
              >
                <Img
                  css={css`
                    height: 300px;
                  `}
                  fluid={data.charger2.childImageSharp.fluid}
                />
                <Card.Body className='bg-dark text-white'>
                  <Card.Title>CAR CHARGER</Card.Title>
                  <Card.Text>ACDELCO</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className='mb-2 mb-sm-4' md='6' lg='3'>
              <Card
                css={css`
                  height: 100%;
                `}
              >
                <Img
                  css={css`
                    height: 300px;
                  `}
                  fluid={data.charger3.childImageSharp.fluid}
                />
                <Card.Body className='bg-dark text-white'>
                  <Card.Title>INDUSTRIAL CHARGER</Card.Title>
                  <Card.Text>MIDTRONICS</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className='d-none d-md-block mb-sm-4' md='6' lg='3'>
              <Card className='px-3 h-100 bg-dark text-white d-flex align-items-center text-center'>
                <div className='my-auto'>
                  <p className='mb-3'>
                    For more charger models..
                    <br />
                  </p>
                  <Link to='/contact-us' className='btn btn-sm btn-primary'>
                    Contact us now!
                  </Link>
                </div>
              </Card>
            </Col>
          </Row>
          <div className='d-md-none mt-4 text-center'>
            <p className='mb-2 text-center'>For more charger models.. </p>
            <Link to='/contact-us' className='btn btn-primary col-8'>
              Contact us now!
            </Link>
          </div>
        </Container>
      </Section>
      <Cta showProducts={false} />
    </Layout>
  )
}

export default ProductsPage
